
import { computed, defineComponent, onMounted, ref } from 'vue';
import { WalletName } from '@solana/wallet-adapter-wallets';
import useCluster, { Cluster } from '@/composables/cluster';
import useWallet from '@/composables/wallet';

export default defineComponent({
  setup() {
    // cluster

    const walletModal = ref<boolean>(false);
    const { cluster, setCluster, getClusterURL } = useCluster();
    const chosenCluster = cluster.value;

    // wallet
    const { getWalletName, setWallet } = useWallet();
    const toggleWalletModal = () => {
      walletModal.value = !walletModal.value
    }
    const onClickWallet = (walletName: WalletName) => {
      setWallet(walletName, getClusterURL());
      return getWalletName();
    }
    const chosenWallet = computed({
      get() {
        return getWalletName();
      },
      set(newVal: WalletName | null) {
        setWallet(newVal, getClusterURL());
      },
    });

    const closeModal = (e:Event) =>{
      if(e.target === e.currentTarget){
        toggleWalletModal();
      }
    }

    return {
      toggleWalletModal,
      walletModal,
      onClickWallet,
      Cluster,
      chosenCluster,
      WalletName,
      closeModal,
      chosenWallet,
    };
  },
});
