import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FixedScheduleDisplay = _resolveComponent("FixedScheduleDisplay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createTextVNode(" Estimated claimable: " + _toDisplayString(Math.floor(((parseInt(_ctx.gems) * (Math.round(_ctx.currentTS/1000) - _ctx.reward.fixedRate.lastUpdatedTs) * 10.368 / 86400)) * 1000 + ((_ctx.reward.accruedReward - _ctx.reward.paidOutReward)))/1000) + " $BACK | ", 1),
    _createVNode(_component_FixedScheduleDisplay, {
      key: _ctx.farmReward,
      gems: _ctx.gems,
      schedule: _ctx.reward.fixedRate.promisedSchedule
    }, null, 8, ["gems", "schedule"])
  ], 64))
}