import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nes-container bg-black text-white uppercase text-center" }
const _hoisted_2 = { class: "mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FarmerRewardDisplay = _resolveComponent("FarmerRewardDisplay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, "Your wallet: " + _toDisplayString(_ctx.farmerAcc.identity.toBase58()), 1),
    _createElementVNode("div", null, [
      _createTextVNode(" Mining back: " + _toDisplayString(_ctx.farmerAcc.gemsStaked) + " | ", 1),
      _createVNode(_component_FarmerRewardDisplay, {
        key: _ctx.farmerAcc.rewardA,
        farmReward: _ctx.farmAcc.rewardA,
        reward: _ctx.farmerAcc.rewardA,
        gems: _ctx.farmerAcc.gemsStaked,
        title: "Reward"
      }, null, 8, ["farmReward", "reward", "gems"])
    ])
  ]))
}